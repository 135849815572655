<template>
  <main id="content" role="main">
    <div class="position-relative z-index-2">

    <!-- Contact Form Section -->
    <div class="d-md-flex">
      <div class="container d-md-flex min-vh-md-100 space-2 space-top-md-3 space-top-lg-3">

      <div class="col-lg-6 mb-9 mb-lg-0 text-white">
        <div class="mb-5">
          <h1 class="text-white">Get in touch</h1>
        </div>

        <div class="row mb-5">
          <div class="col-sm-6">
            <div class="mb-3">
              <span class="d-block h5 mb-1 text-white">Call us:</span>
              <a href="tel:0031617437690" class="d-block text-white font-size-2">+31 (617) 437690</a>
            </div>

            <div class="mb-3">
              <span class="d-block h5 mb-1 text-white">Email us:</span>
              <a href="mailto:hello@savelocal.org" class="d-block text-white font-size-2">
                hello@savelocal.org
              </a>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mb-3">
              <span class="d-block h5 mb-1 text-white">Locations:</span>
              <span class="d-block text-white font-size-2">Amsterdam<br>Cape Town</span>
            </div>
          </div>
        </div>

        <div class="mb-1">
          <h1 class="text-white mb-3">Joining the team</h1>
          <p class="d-block text-white font-size-2">
            Please let us know if you are interested in joining our team.
            Among many other roles, we are in particular on the lookout for
            <span class="text-primary">social media</span>,
            <span class="text-primary">conservation</span> and
            <span class="text-primary">tech</span> experts!
          </p>
        </div>

      </div>

      <div class="col-lg-6">
        <div class="ml-lg-5">
          <!-- Form -->
          <form class="js-validate card shadow-lg mb-4" action="https://formspree.io/f/xyylwkqk" method="POST">
            <div class="card-header border-0 bg-light text-center py-4 px-4 px-md-6">
              <h2 class="h4 mb-0">General Enquiries</h2>
            </div>

            <div class="card-body p-4 p-md-6">
              <div class="row">
                <div class="col-sm-4">
                  <!-- Form Group -->
                  <div class="js-form-message form-group">
                    <label for="firstName" class="input-label">First name</label>
                    <input type="text" class="form-control" name="firstName" id="firstName" placeholder="Name" required
                            data-msg="Please enter first your name">
                  </div>
                  <!-- End Form Group -->
                </div>

                <div class="col-sm-8">
                  <!-- Form Group -->
                  <div class="js-form-message form-group">
                    <label for="emailAddress" class="input-label">Email address</label>
                    <input type="email" class="form-control" name="emailAddress" id="emailAddress" placeholder="Email" required
                            data-msg="Please enter your email address">
                  </div>
                  <!-- End Form Group -->
                </div>

                <div class="col-sm-12">
                  <!-- Form Group -->
                  <div class="js-form-message form-group">
                    <label for="message" class="input-label">Message</label>
                    <div class="input-group">
                      <textarea class="form-control" rows="4" name="message" id="message" placeholder="Hi there, I would like to ..." aria-label="Hi there, I would like to ..." required
                                data-msg="Please enter a reason."></textarea>
                    </div>
                  </div>
                  <!-- End Form Group -->
                </div>
              </div>

              <button type="submit" class="btn btn-block btn-primary transition-3d-hover">Submit</button>
            </div>
          </form>
          <!-- End Form -->

          <div class="text-center">
            <p class="text-lead text-white">We'll get back to you as soon as possible.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- End Contact Form Section -->

    <div class="position-absolute bottom-0 right-0 left-0 text-center">
        <div class="container-fluid text-white py-5" style="opacity:0.3">
          <i class="fa fa-map-marker-alt"></i>
          <span> Arches National Park, United States - Photo by <a href="https://unsplash.com" target="_blank" style="color:inherit;text-decoration:underline">Dino Reichmuth</a></span>
        </div>
      </div>
    </div>

    <div class="position-absolute top-0 right-0 bottom-0 left-0 bg-img-hero" style="background-image:linear-gradient(to bottom, rgba(19, 24, 54, 0.80), rgba(19, 24, 54, 0.70)),url(./assets/img/cover-van.jpg);"></div>
  </main>
</template>

<script>
</script>

<style lang="scss" scoped>
</style>
